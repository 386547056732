@@ -1,335 +0,0 @@
<template>
    <div class="staking-single">
        <DefaultCharacter :isLoadingData="isLoadingData" :character="GET_CHARACTER">
            <template slot="header">
                <url-path :urls="getPathUrls" class="market-place-header__urls" />
            </template>

            <template v-if="GET_CHARACTER" slot="actions">
                <btn
                    class="avatar-btn"
                    type="icon-gradient"
                    text="? MF"
                    :selected="toLocaleFormat(GET_CHARACTER.staking_price)"
                    :hoverText="`STAKE ${toLocaleFormat(GET_CHARACTER.staking_usd_price) || 1000} $`"
                    @click="onNftStakeClick(GET_CHARACTER)"
                />
            </template>
        </DefaultCharacter>

        <preloader translucent black v-if="isProcessing" />
        <stake-success-popup ref="stakeSuccessPopup"/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultCharacter from '@/components/common/default-character.vue'
import UrlPath from '@/components/common/url-path.vue'
import stakeMixin from "@/mixins/stakeMixin";

export default {
  name: 'StakingSingle',
  mixins: [stakeMixin],
  props: {
    person: { type: String, default: '' },
  },
  components: {
    UrlPath,
    DefaultCharacter,
  },
  data() {
    return {
        isLoadingData: true,
    }
  },
  computed: {
    ...mapGetters([
      'GET_IS_MOBILE',
      'GET_CHARACTER',
    ]),

    getPathUrls() {
      return [
        {
          label: 'Staking',
          name: 'Staking',
        },
        {
          label: this.person,
        },
      ]
    },
  },

  async mounted() {
    await this.REQUEST_CHARACTER(this.person)

    this.isLoadingData = false;
  },

  methods: {
    ...mapActions([
      'REQUEST_CHARACTER',
    ]),
  },
}
</script>

<style lang="scss">
.staking-single {
    .avatar-btn {
        color: black;
        width: 210px;
        margin-top: 3em;
        border: none;

        @media screen and (max-width: 1250px) {
            margin-left: calc(50% - 100px);
        }
    }
}
</style>
