// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".staking-single .avatar-btn {\n  color: black;\n  width: 210px;\n  margin-top: 3em;\n  border: none;\n}\n@media screen and (max-width: 1250px) {\n.staking-single .avatar-btn {\n    margin-left: calc(50% - 100px);\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
