var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staking-single" },
    [
      _c(
        "DefaultCharacter",
        {
          attrs: {
            isLoadingData: _vm.isLoadingData,
            character: _vm.GET_CHARACTER,
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _c("url-path", {
                staticClass: "market-place-header__urls",
                attrs: { urls: _vm.getPathUrls },
              }),
            ],
            1
          ),
          _vm.GET_CHARACTER
            ? _c(
                "template",
                { slot: "actions" },
                [
                  _c("btn", {
                    staticClass: "avatar-btn",
                    attrs: {
                      type: "icon-gradient",
                      text: "? MF",
                      selected: _vm.toLocaleFormat(
                        _vm.GET_CHARACTER.staking_price
                      ),
                      hoverText: `STAKE ${
                        _vm.toLocaleFormat(
                          _vm.GET_CHARACTER.staking_usd_price
                        ) || 1000
                      } $`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onNftStakeClick(_vm.GET_CHARACTER)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.isProcessing
        ? _c("preloader", { attrs: { translucent: "", black: "" } })
        : _vm._e(),
      _c("stake-success-popup", { ref: "stakeSuccessPopup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }